import {
    IChartAction,
    IPreviewAction,
    ISourceSetAction,
} from './ui/discovery/types';

import { Geometry } from 'ol/geom';

export type TPrimarySourceSetIds =
    | 'lastStates'
    | 'locations'
    | 'routes'
    | 'tasks';

export interface ISourceSetIds {
    archiveEvents: 'archiveEvents';
    lastStates: 'lastStates';
    locations: 'locations';
    routes: 'routes';
    tasks: 'tasks';
    tasksPreview: 'tasksPreview';
}
export const sourceSetIds: ISourceSetIds = {
    archiveEvents: 'archiveEvents',
    lastStates: 'lastStates',
    locations: 'locations',
    routes: 'routes',
    tasks: 'tasks',
    tasksPreview: 'tasksPreview',
};

export interface IComponentNames {
    frameDetails: 'frameDetails';
    aggregationDetails: 'aggregationDetails';
    editEvent: 'editEvent';
}

export const componentNames: IComponentNames = {
    frameDetails: 'frameDetails',
    aggregationDetails: 'aggregationDetails',
    editEvent: 'editEvent',
};
export interface ISearchResult {
    id: string;
    name: string;
    type: string;
    lastStateExtract: {
        eventName: string;
        location: string;
        coordinate: {
            x: number;
            y: number;
        };
        time: string;
    };
    alerts: number;
}

export interface IPage<V> {
    values: V[];
}
export interface IAddress {
    city: string;
    street?: string;
    streetNumber?: string;
    zip?: string;
    apartmentNo?: string;
    areaName0?: string; // country
    areaName1?: string; // voivodeship
    areaName2?: string; // county
    areaName3?: string; // commune
}
export interface ILocation {
    id: string;
    name: string;
    notes: string;
    clientId: number;
    externalNo: string;
    address: IAddress;
    externalId: string;
    coordinate: {
        x: number;
        y: number;
    };
    groupIds?: string[];
}
export interface ITrail {
    id: string;
    data: {
        type: string;
        features: {
            geometry: {
                coordinates: [][];
                type: string;
            };
            properties: {
                archiveId: number;
                from: {
                    dateTime: string;
                };
                objectId: number;
                to: {
                    dateTime: string;
                };
            };
        }[];
    };
    level: number;
    color?: string;
}

export interface IMonitoredObject {
    id: string;
    name: string;
    monitoredId: string;
    type: string;
    state: {
        name: string;
    };
    location?: object;
    utcUpdatedAt: string;
    alerts?: number;
}

export interface IMovie {
    paramName: string;
    offset: number;
    fileId: string;
    fileName: string;
    length: number;
    frameValid: boolean;
    labels?: string;
}

export interface IActionDataParams {
    id?: string;
    name?: string;
    monitoredId?: number;
    deviceId?: number;
    from?: string;
    to?: string;
    blocked?: boolean;
    movies?: IMovie[];
    offset?: number;
    updatatedAt?: string;
    icon?: IIcon;
    startDate?: string;
    latitude?: string;
    longitude?: string;
    objectId?: number;
    address?: {
        city: string;
        street: string;
        streetNumber: string;
    };
}

export interface IActionData {
    params: IActionDataParams;
    componentName?: string;
    label?: string;
    method?: string;
    api: string;
}

export interface ISourceSetEntityParams {
    [key: string]: string | number | boolean;
}

export interface ICoordinates {
    x: number;
    y: number;
}

export interface ISourceSetEntity {
    updatedAt?: string;
    name?: string;
    plannedOn?: string;
    statusDate?: string;
    startDate?: string;
    offset?: number;
    externalId?: string;
    idClient?: number;
    TASKID?: string;
    clientId?: number;
    locationId?: string;
    address?: string;
    city?: string;
    street?: string;
    apartmentNo?: string;
    postalCode?: string;
    commune?: string;
    locationName?: string;
    routeId?: number;
    statusId?: string;
    color?: {
        rgb: {
            r: number;
            g: number;
            b: number;
        };
    };
    _meta: {
        coordinates?: ICoordinates;
        actions?: {
            [key: string]: IActionData;
        };
        icon?: IIcon;
        type?: string;
    };
    entities?: TSourceSetEntity[];
    id: string;
    location?:
        | string
        | {
              gps: {
                  latitude: number;
                  longitude: number;
              };
          };
    type?: string;
    recurringId?: number;
    gridStatus?: string;
    coordinate?: { x: number; y: number };
    cycleId?: number;
    contractItemId?: string;
    TACHO_DR1_WORK_ST?: number;
    TACHO_DR2_WORK_ST?: number;
}

export interface ILocationEntity {
    externalId: string;
    name: string;
}

export interface IReportSetParams {
    monitoredId: number;
    from: string;
    to: string;
    blocked: boolean;
    movies?: IMovie[];
}

export type TSourceSetEntity = ISourceSetEntity;

export interface ISymbolPaths {
    car?: string;
    binOnWheels?: string;
    forkLift?: string;
}

export interface IAttribute {
    id: string;
    label: string;
    type: string;
    translate?: string;
    formatting?: ICustomFormat;
    children?: IAttribute[];
    selectable?: boolean;
    rowGroup?: boolean;
    cellClass?: 'booleanType';
    showAlways?: boolean;
    columnGroupShow?: string;
    suppressColumnsToolPanel?: boolean;
    collapsed?: boolean;
}

export interface ICustomFormat {
    pattern?: 'short' | string;
    decimalPlaces?: number;
    asDuration?: boolean;
    suffix?: string;
    translate?: string;
    separator?: string;
}
export interface IIcon {
    id: string;
    name: string;
    type?: 'info' | 'action';
    params?: {
        iconBorderColor?: string;
        iconColor?: string;
        iconFillColor?: string;
        iconFillHeight?: number;
        iconFillHeightNegative?: number;
    };
    sourceSetId?: string;
    data?: {
        name?: string;
    };
    label?: string;
}

export interface ISourceSetActionParams {
    [key: string]: string | number | boolean;
}
export interface ISourceSetActionPostPut {
    body: object;
    method: 'POST' | 'PUT';
}

export interface ISourceSetActionGetDelete {
    method: 'GET' | 'DELETE';
}

export type TSourceSetAction = ISourceSetAction &
    (ISourceSetActionPostPut | ISourceSetActionGetDelete);

interface IGlobalEntity {
    id: string;
    label: string;
    value: string | number | boolean | IBinaryValue;
    type: string;
    translate?: string;
}

export interface ISourceSet {
    id: string;
    definitionId: string;
    componentName?: string;
    label: string;
    attributes: IAttribute[];
    layersAttributes: IAttribute[];
    _meta: {
        symbolPaths?: ISymbolPaths;
        geolocated?: boolean;
        icon?: IIcon;
        autoRefreshId?: string;
        actions?: {
            [key: string]: TSourceSetAction;
        };
        globalEntities?: IGlobalEntity[];

        // it contains string keys of actions object
        contextMenu?: string[];
        defaultAction?: string;
        sorting?: { orderBy: string; sortOrder: 'asc' | 'desc' };
    };
    entities: TSourceSetEntity[];
    messages?: ISourceSetMessage[];
}

export interface IBinaryValue {
    '@type': string;
    name: string;
    isMovie?: boolean;
}

export interface IBinaryAction {
    method: string;
    api: string;
    label?: string;
}

export interface IExternalLinkAction {
    url: string;
}

export interface IInternalLinkAction {
    url: string;
    params: {
        id: string;
    };
    openInNewTab: boolean;
    _meta: {
        icon: IIcon;
    };
}

export interface IOnClickAction {
    componentName?: string;
    params?: { event: string };
    disabled?: boolean;
}

export interface IReportEntity {
    id: string;
    label: string;
    translatedLabel?: string | null;
    value: string | number | IBinaryValue | boolean;
    type: string;
    translate?: string;
    translateValue?: string;
    actions?: {
        preview?: IActionData;
        download?: IBinaryAction;
        externalLink?: IExternalLinkAction;
        internalLink?: IInternalLinkAction;
        add?: { params: IActionDataParams };
        edit?: { params: { id: string } };
        delete?: { params: { id: string } };
        sourceSet?: ISourceSetAction;
        showImagePreview?: IBinaryAction;
        showImageDownload?: IBinaryAction;
        onClick?: {
            componentName?: string;
            disabled?: boolean;
            label: string;
            params: {
                monitoredId: number;
                from: string;
                to: string;
                reportId: string;
                event: string;
            };
            _meta?: {
                params: {
                    from: {
                        timeZoned: boolean;
                    };
                };
            };
        };

        stateOff?: IToggleButtonMeta;
        stateOn?: IToggleButtonMeta;
    };
    formatting?: ICustomFormat;
    _meta?: {
        icon?: IIcon;
        placement: 'topBar' | 'header';
    };
}

export interface IReportActions {
    sourceSet?: ISourceSetAction;
    chart?: IChartAction;
    add?: IPreviewAction;
    edit?: IPreviewAction;
    delete?: IPreviewAction;
    preview?: IPreviewAction;
}
export interface IReportMeta {
    expanded?: boolean;
    actions?: IReportActions;
    componentName?: string;
    sorted?: boolean;
}
export interface IReport {
    id: string;
    label: string;
    labelParams?: string[];
    _meta?: IReportMeta;
    entities: IReportEntity[];
    translate?: string;
}

export interface IReportSetHeader {
    date: string;
    icon: IIcon;
    name: string;
    coordinates: ICoordinates;
    location: string;
    monitoredId: string;
    stateName: string;
    type: number;
    updatedAt: string;
    offset?: number;
    objectName?: string;
}

export interface IReportSetMeta {
    header?: IReportSetHeader;
    params?: {
        monitoredId?: string;
        from?: string;
        to?: string;
    };
    menu?: IReportEntity[];
}
export interface IReportSet {
    id: string;
    _meta?: IReportSetMeta;
    reports: IReport[];
    type?: string;
    from?: string;
    to?: string;
    monitoredId: string;
}

export interface ILastStateHeader {
    name: string;
    monitoredId: number;
    updatedAt: string;
    stateName: string;
    location: string;
    type: number;
    capacity: number;
    coordinates?: ICoordinates;
    messages?: ISourceSetMessage[];
    externalId?: number;
    icon?: IIcon;
}

export interface ILastState {
    id: string;
    reports: IReport[];
    _meta?: {
        header?: ILastStateHeader;
        menu?: IReportEntity[];
    };
}

export interface ITaskDetails {
    id: number;
    plannedOn: string;
    plannedTimeFrom: string;
    plannedTimeTo: string;
    noDate?: boolean;
    vehicleName?: string;
    vehicleId?: number;
    employeeName?: string;
    employeeId?: number;
    location: {
        id: number;
        name: string;
        address: string;
    };
    statusDate: string;
    statusName: string;
    statusId: number;
    readOnly: boolean;
    notice?: string;
    unitsCount: number;
    activityCategoryId?: number;
    serviceTypeId?: number;
    objectCategoryId?: number;
    serviceClassId?: number;
    attachments?: Array<{ name: string; fileId: number }>;
    externalLinkages?: Array<{ appName: string; externalId: string }>;
}

export interface ITaskHeader {
    task: ITaskDetails;
    coordinates?: ICoordinates;
    icon: IIcon;
}

export interface IEntityWithAction extends IGlobalEntity {
    actions: Record<
        string,
        {
            label: string;
            params: {
                monitoredId?: number;
                from?: string;
                to?: string;
                reportId?: string;
            };
            _meta?: {
                params: {
                    from: {
                        timeZoned: boolean;
                    };
                };
            };
        }
    >;
}

export interface ITask {
    id: string;
    reports: IReport[];
    _meta: {
        header: ITaskHeader;
        menu: IEntityWithAction[];
    };
}
export type TPreviewType =
    | 'monitoredObject'
    | 'reportSet'
    | 'sourceSetElement'
    | 'location'
    | 'task'
    | 'alertSetting'
    | 'frameDetails'
    | 'aggregationDetails';

export const previewTypes: { [key: string]: TPreviewType } = {
    [sourceSetIds.locations]: 'location',
    [sourceSetIds.lastStates]: 'monitoredObject',
    [sourceSetIds.tasks]: 'task',
    [sourceSetIds.tasksPreview]: 'task',
    [componentNames.frameDetails]: 'frameDetails',
    [componentNames.aggregationDetails]: 'aggregationDetails',
};

export interface IBookmark {
    from: string;
    id: string;
    level: number;
    monitoredId: string;
    monitoredName: string;
    to: string;
    snapshot: any;
    snapshotPaths: any;
    timeLocked: boolean;
    color?: string;
}

export interface IBookmarkDataSegment extends IBookmark {
    color: string;
}

export interface IToggleButtonMeta {
    label: string;
    method: string;
    api: string;
    body: { [key: string]: any };
}

export interface ISourceSetMessage {
    content: string;
    translate: string;
    level: 'info' | 'warning' | 'error';
}

export interface ILayersData {
    id: string;
    label: string;
    type: string;
    title: string;
    features: ILayersFeaturesData[];
}

export interface ILayersFeaturesData {
    geometry?: Geometry;
    label: string;
}

export interface IDevice {
    id: number;
    serialNumber: string;
    name?: string;
    group?: string;
    attributes?: IAttributes;
    integrations?: IIntegrations;
    eTollTranslated?: string;
    recurringId: number;
}

interface IAttributes {
    eToll: boolean;
}

interface IIntegrations {
    eToll: IIntegrationsEToll;
}

interface IIntegrationsEToll {
    status: IntegrationsETollStatus | string;
    registeredAt: string;
    businessNumber: string;
    pin: string;
}

export enum IntegrationsETollStatus {
    'TO_REGISTER',
    'SENT_REGISTER_REQUEST',
    'REGISTRATION_CONFIRMED',
    'REGISTRATION_FAILED',
    'MANUAL_CONFIRMED',
}

export type TStatus = 'idle' | 'loading' | 'done' | 'error';

interface Idle {
    type: 'idle';
}

interface Loading {
    type: 'loading';
}

interface Done<T> {
    type: 'done';
    data: T[];
}

interface FetchError {
    type: 'error';
    error: { message: string };
}

export type TFetchState<T> = Idle | Loading | Done<T> | FetchError;

export interface IExternalSection {
    id: 'smartSteelMachinesObject';
    title: string;
    tenants: string[];
}
export interface IExternalSectionHeaders {
    [key: string]: string;
}
export interface ISourceSetExternalSectionItem {
    label: string;
    value: string;
}

export interface IMappedMenuItem {
    id: number | string;
    key: string;
    name: string;
}
